import { PackageInstructionType } from './types';

export const US_STATES = [
    { name: 'Alabama', abbreviation: 'AL' },
    { name: 'Alaska', abbreviation: 'AK' },
    { name: 'American Samoa', abbreviation: 'AS' },
    { name: 'Arizona', abbreviation: 'AZ' },
    { name: 'Arkansas', abbreviation: 'AR' },
    { name: 'California', abbreviation: 'CA' },
    { name: 'Colorado', abbreviation: 'CO' },
    { name: 'Connecticut', abbreviation: 'CT' },
    { name: 'Delaware', abbreviation: 'DE' },
    { name: 'District of Columbia', abbreviation: 'DC' },
    { name: 'Federated States of Micronesia', abbreviation: 'FM' },
    { name: 'Florida', abbreviation: 'FL' },
    { name: 'Georgia', abbreviation: 'GA' },
    { name: 'Guam', abbreviation: 'GU' },
    { name: 'Hawaii', abbreviation: 'HI' },
    { name: 'Idaho', abbreviation: 'ID' },
    { name: 'Illinois', abbreviation: 'IL' },
    { name: 'Indiana', abbreviation: 'IN' },
    { name: 'Iowa', abbreviation: 'IA' },
    { name: 'Kansas', abbreviation: 'KS' },
    { name: 'Kentucky', abbreviation: 'KY' },
    { name: 'Louisiana', abbreviation: 'LA' },
    { name: 'Maine', abbreviation: 'ME' },
    { name: 'Marshall Islands', abbreviation: 'MH' },
    { name: 'Maryland', abbreviation: 'MD' },
    { name: 'Massachusetts', abbreviation: 'MA' },
    { name: 'Michigan', abbreviation: 'MI' },
    { name: 'Minnesota', abbreviation: 'MN' },
    { name: 'Mississippi', abbreviation: 'MS' },
    { name: 'Missouri', abbreviation: 'MO' },
    { name: 'Montana', abbreviation: 'MT' },
    { name: 'Nebraska', abbreviation: 'NE' },
    { name: 'Nevada', abbreviation: 'NV' },
    { name: 'New Hampshire', abbreviation: 'NH' },
    { name: 'New Jersey', abbreviation: 'NJ' },
    { name: 'New Mexico', abbreviation: 'NM' },
    { name: 'New York', abbreviation: 'NY' },
    { name: 'North Carolina', abbreviation: 'NC' },
    { name: 'North Dakota', abbreviation: 'ND' },
    { name: 'Northern Mariana Islands', abbreviation: 'MP' },
    { name: 'Ohio', abbreviation: 'OH' },
    { name: 'Oklahoma', abbreviation: 'OK' },
    { name: 'Oregon', abbreviation: 'OR' },
    { name: 'Palau', abbreviation: 'PW' },
    { name: 'Pennsylvania', abbreviation: 'PA' },
    { name: 'Puerto Rico', abbreviation: 'PR' },
    { name: 'Rhode Island', abbreviation: 'RI' },
    { name: 'South Carolina', abbreviation: 'SC' },
    { name: 'South Dakota', abbreviation: 'SD' },
    { name: 'Tennessee', abbreviation: 'TN' },
    { name: 'Texas', abbreviation: 'TX' },
    { name: 'Utah', abbreviation: 'UT' },
    { name: 'Vermont', abbreviation: 'VT' },
    { name: 'Virgin Islands', abbreviation: 'VI' },
    { name: 'Virginia', abbreviation: 'VA' },
    { name: 'Washington', abbreviation: 'WA' },
    { name: 'West Virginia', abbreviation: 'WV' },
    { name: 'Wisconsin', abbreviation: 'WI' },
    { name: 'Wyoming', abbreviation: 'WY' },
];

export const US_STATES_MAP: { [key: string]: string } = {
    AL: 'Alabama',
    AK: 'Alaska',
    AS: 'American Samoa',
    AZ: 'Arizona',
    AR: 'Arkansas',
    CA: 'California',
    CO: 'Colorado',
    CT: 'Connecticut',
    DE: 'Delaware',
    DC: 'District of Columbia',
    FM: 'Federated States of Micronesia',
    FL: 'Florida',
    GA: 'Georgia',
    GU: 'Guam',
    HI: 'Hawaii',
    ID: 'Idaho',
    IL: 'Illinois',
    IN: 'Indiana',
    IA: 'Iowa',
    KS: 'Kansas',
    KY: 'Kentucky',
    LA: 'Louisiana',
    ME: 'Maine',
    MH: 'Marshall Islands',
    MD: 'Maryland',
    MA: 'Massachusetts',
    MI: 'Michigan',
    MN: 'Minnesota',
    MS: 'Mississippi',
    MO: 'Missouri',
    MT: 'Montana',
    NE: 'Nebraska',
    NV: 'Nevada',
    NH: 'New Hampshire',
    NJ: 'New Jersey',
    NM: 'New Mexico',
    NY: 'New York',
    NC: 'North Carolina',
    ND: 'North Dakota',
    MP: 'Northern Mariana Islands',
    OH: 'Ohio',
    OK: 'Oklahoma',
    OR: 'Oregon',
    PW: 'Palau',
    PA: 'Pennsylvania',
    PR: 'Puerto Rico',
    RI: 'Rhode Island',
    SC: 'South Carolina',
    SD: 'South Dakota',
    TN: 'Tennessee',
    TX: 'Texas',
    UT: 'Utah',
    VT: 'Vermont',
    VI: 'Virgin Islands',
    VA: 'Virginia',
    WA: 'Washington',
    WV: 'West Virginia',
    WI: 'Wisconsin',
    WY: 'Wyoming',
};

export const INSTRUCTION_FREQUENCY_REGEX: RegExp =
    /^q([1-9][0-9]*)(?:h|hr|hour)$|(^q([1-9][0-9]*)d$)|^(once|qqh|qid|tid|bid|qd|sid|qod)$/gi;

export const DIAGNOSTIC_REGEXES = [
    {
        key: 0,
        label: 'Positive Integer',
        expression: '^[1-9][0-9]*$',
    },
    {
        key: 1,
        label: 'Positive Numeric',
        expression: '^[0-9]+.?[0-9]*$',
    },
    { key: 2, label: 'Yes/No', expression: '' },
];

export const PACKAGE_INSTRUCTION_COLORS = {
    [PackageInstructionType.TASK]: '#6ebdf5',
    [PackageInstructionType.DIAGNOSTIC]: '#ac89cc',
    [PackageInstructionType.MEDICATION]: '#4dd2a8',
    [PackageInstructionType.FLUID]: '#ffab61',
    [PackageInstructionType.CRI]: '#6D68FF',
    [PackageInstructionType.NON_MEDICAL]: '#ffa39e',
};

export const FLUIDS = [
    { id: 1, name: 'Dextrose 5% in water', abbreviation: 'D5W' },
    { id: 2, name: '0.45% Saline', abbreviation: '1/2 NS' },
    { id: 4, name: '0.9% Saline', abbreviation: 'NS' },
    { id: 5, name: 'Lactated Ringers', abbreviation: 'LRS' },
    { id: 6, name: 'Plasma Lyte A', abbreviation: 'Plyte' },
    { id: 7, name: 'Normosol R', abbreviation: 'NormR' },
    { id: 8, name: 'Sterile Water', abbreviation: '' },
];

export const FLUIDS_WITH_EMPTY = [{ id: -1, name: ' ', abbreviation: '' }, ...FLUIDS];

export const FLUID_ROUTES = ['', 'IV', 'IO', 'SQ'] as const;

export const LOCAL_STORAGE_USER_KEY = 'veg_user';

export const BASE_QUERY_OPTIONS = {
    pollingInterval: 60000000,
};

const { REACT_APP_API_URL, REACT_APP_ENV, REACT_APP_URL } = process.env;

const getendPoints = (
    webHost: string,
    appEnv?: string,
    appApiUrl?: string,
    appUrl?: string,
): { endpoint?: string; appUrlLocal?: string } => {
    let dogbyte_env: string | undefined = undefined;
    const isGreen = host.includes('green');
    const isBlue = host.includes('blue');
    let endpointLocal = appApiUrl;
    let appUrlLocal = appUrl;

    if (REACT_APP_ENV === 'local' || !appEnv) {
        endpointLocal = appApiUrl;
        appUrlLocal = appUrl;
    } else if (REACT_APP_ENV === 'prd') {
        dogbyte_env = webHost?.split('.')?.[0]?.split('admin-')?.[1];
        if (dogbyte_env) {
            endpointLocal = 'https://' + 'api-' + dogbyte_env + '.' + appApiUrl;
            appUrlLocal = 'https://' + 'admin-' + dogbyte_env + '.' + appUrl;
        } else if (isGreen) {
            endpointLocal = 'https://' + 'api-' + 'green' + '.' + appApiUrl;
            appUrlLocal = 'https://' + 'admin-' + 'green' + '.' + appUrl;
        } else if (isBlue) {
            endpointLocal = 'https://' + 'api-' + 'blue' + '.' + appApiUrl;
            appUrlLocal = 'https://' + 'admin-' + 'blue' + '.' + appUrl;
        } else {
            endpointLocal = 'https://' + 'api' + '.' + appApiUrl;
            appUrlLocal = 'https://' + 'admin' + '.' + appUrl;
        }
    } else {
        dogbyte_env = webHost?.split('.')?.[0]?.split('admin-')?.[1];
        if (dogbyte_env) {
            endpointLocal = 'https://' + 'api-' + dogbyte_env + '.' + appApiUrl;
            appUrlLocal = 'https://' + 'admin-' + dogbyte_env + '.' + appUrl;
        } else if (isGreen) {
            endpointLocal = 'https://' + 'api-' + 'green-' + appEnv + '.' + appApiUrl;
            appUrlLocal = 'https://' + 'admin-' + 'green-' + appEnv + '.' + appUrl;
        } else if (isBlue) {
            endpointLocal = 'https://' + 'api-' + 'blue-' + appEnv + '.' + appApiUrl;
            appUrlLocal = 'https://' + 'admin-' + 'blue-' + appEnv + '.' + appUrl;
        } else {
            endpointLocal = 'https://' + 'api-' + appEnv + '.' + appApiUrl;
            appUrlLocal = 'https://' + 'admin-' + appEnv + '.' + appUrl;
        }
    }

    return {
        endpoint: endpointLocal,
        appUrlLocal,
    };
};

const host = window.location.host;
// const isGreen = host.includes('green');
// const isBlue = host.includes('blue');

const { endpoint: endpointTemp, appUrlLocal } = getendPoints(host, REACT_APP_ENV, REACT_APP_API_URL, REACT_APP_URL);

export const appUrl = appUrlLocal;

export const endpoint = endpointTemp;
export const AUTH_HOST = `${endpointTemp}/auth`;
export const RDVM_UPLOAD_TEMPLATE =
    'https://docs.google.com/spreadsheets/d/1_OqghvvsONLWMJhAxRjVLI75MnSeOBrDyRadLGttr84/edit#gid=1187789643';

export const MEETING_TYPES = ['Call', 'Email', 'In Person Visit', 'Other'];
export const MEETING_PRIORITY = ['Low', 'Medium', 'High'];
export const MEETING_STAGE = [
    'New/Yet to be determined',
    'No current interest',
    'Somewhat interested',
    'Very Interested',
    'Agreement - Working on IT Set-up',
    'Trial',
    'VEGetarian',
];

export const SUPPORTED_ZIPCODE_REGEX = /^[0-9]{5}$/i;
export const SUPPORTED_PHONE_NUMBER_REGEX = /^[0-9]{10}$/i;
export const POSITIVE_INTEGER = new RegExp('^[1-9][0-9]*$');
export const FLOAT_REGEX = new RegExp('^[0-9]+\\.?[0-9]*$');
export const SIGNED_FLOAT_REGEX = new RegExp('^[-]?[0-9]+\\.?[0-9]*$');

export const TIMEZONES = ['America/New_York', 'America/Chicago', 'America/Phoenix', 'America/Denver', 'America/Los_Angeles'];

export const RDVM_STATUS = [
    { name: 'Open', value: 'Open' },
    { name: 'Temporarily Closed', value: 'Temporarily closed' },
    { name: 'Permanently Closed', value: 'Permanently closed' },
    { name: 'Coming Soon', value: 'Coming soon' },
];

export const RDVM_FACILITY = [
    { name: 'General Practice', value: 'general_practice' },
    { name: 'Urgent Care', value: 'urgent_care' },
    { name: 'Hospital', value: 'hospital' },
    { name: 'Shelter/Rescue', value: 'shelter_rescue' },
];

export const RDVM_EMERGENCY_CARE_OFFERINGS = [
    { name: 'None', value: 'none' },
    { name: 'ER', value: 'er' },
    { name: 'ER & Speciality', value: 'er_speciality' },
    { name: 'Urgent Care', value: 'urgent_care' },
];

export const RDVM_HOURS = [
    { name: 'Normal Business Hours', value: 'normal_business_hours' },
    { name: 'Extended Hours', value: 'extended_hours' },
    { name: 'Overnight', value: 'overnight' },
    { name: '24/7', value: '24_7' },
];

export const RDVM_WEEKEND_OPERATIONS = [
    { name: 'None', value: 'none' },
    { name: 'Saturday', value: 'saturday' },
    { name: 'Sunday', value: 'sunday' },
    { name: 'Saturday and Sunday', value: 'saturday_and_sunday' },
];

export const RDVM_DISCOUNT_TYPE = [
    { name: 'None', value: 'None' },
    { name: 'Percentage', value: 'Percentage' },
    { name: 'Fixed Amount', value: 'Fixed amount' },
    { name: 'Other', value: 'Other' },
];

export const RDVM_RELATIONSHIP_STATUS = [
    { name: 'New/Yet to be Determined', value: 'new_yet_to_be_determined' },
    { name: 'No Current Interest', value: 'no_current_interest' },
    { name: 'Somewhat Interested', value: 'somewhat_interested' },
    { name: 'Very Interested', value: 'very_interested' },
    { name: 'Agreement - Working on IT Set-up', value: 'agreement_working_on_it_setup' },
    { name: 'Trial', value: 'trial' },
    { name: 'VEGetarian', value: 'vegetarian' },
];

export const RDVM_PRIORITY = [
    { name: 'Low', value: 'low' },
    { name: 'Medium', value: 'medium' },
    { name: 'High', value: 'high' },
];
